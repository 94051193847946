import { inject, Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { map, Observable, throwError } from 'rxjs';
import { environment } from '@env/environment';
import { AuthService } from '@services/auth.service';
import {
  AuthUserModel,
  AuthUserRoleEnum,
  ILoginReqBody,
  VerificationOptionEnum
} from '@models/auth.model';
import {
  AccountFormModel,
  IAgreementReqBody,
  ICreateAccountReqBody,
  IRecoverPasswordReqBody,
  IUserVerifyReqBody
} from '@models/account-form.model';
import { catchError, tap } from 'rxjs/operators';
import { IDENT_EXP_DATE_KEY, IDENT_KEY } from '@utils/storage.utils';
import {
  Check2faVerificationCodeReqPayload,
  ITwoFAAuthenticationInfo,
  TwoFaInfoReqPayload
} from '@app/pages/my/features/settings/data-access/models/account-settings.model';

@Injectable({
  providedIn: 'root'
})
export class AccountService {
  #httpClient = inject(HttpClient);
  #authService = inject(AuthService);
  #baseUrl = `${environment.apiUrl}/account`;

  login(body: ILoginReqBody, remember: boolean): Observable<AuthUserModel> {
    const url = `${this.#baseUrl}/token`;

    if (remember) {
      this.#authService.setRemember();
    }

    return this.#httpClient
      .post<AuthUserModel>(url, body)
      .pipe(map((res) => AuthUserModel.fromJson(res)));
  }

  getAccountByWithPermissionChange(
    accountId: string
  ): Observable<AuthUserModel> {
    const url = `${this.#baseUrl}/${accountId}`;

    return this.#httpClient.get<AuthUserModel>(url).pipe(
      map((json) => {
        this.#authService.decodeUserAccount();
        const decodeUserAccount: AuthUserModel =
          this.#authService.decodedUserAccount;
        if (decodeUserAccount.role === AuthUserRoleEnum.Admin) {
          decodeUserAccount.permissions = ['*'];
        }
        json['permissions'] = decodeUserAccount.permissions;

        return AuthUserModel.fromJson(json);
      }),
      catchError((err: HttpErrorResponse) => {
        return throwError(() => err.message);
      })
    );
  }

  getAccount(accountId: number): Observable<AuthUserModel> {
    const url = `${this.#baseUrl}/${accountId}`;

    return this.#httpClient
      .get(url)
      .pipe(map((json) => AuthUserModel.fromJson(json)));
  }

  getAccountFormData(): Observable<AccountFormModel> {
    const url = `${this.#baseUrl}/form-data`;

    return this.#httpClient
      .get<AccountFormModel>(url)
      .pipe(map((json) => AccountFormModel.fromJson(json)));
  }

  checkEmailExisting(body: { email: string }) {
    const url = `${this.#baseUrl}/email/_check`;

    return this.#httpClient.post<{ exists: true }>(url, body).pipe(
      catchError((error: HttpErrorResponse) => {
        return throwError(() => new Error(error.error.message));
      })
    );
  }

  resendDeviceVerificationCode(data: {
    accountId: number;
    jwt: string;
  }): Observable<unknown> {
    const url = `${this.#baseUrl}/token/verification-code/resend`;

    const body = {
      jwt: data.jwt,
      account_id: data.accountId
    };

    return this.#httpClient.post<{}>(url, body);
  }

  get2faInfo(
    payload: TwoFaInfoReqPayload
  ): Observable<ITwoFAAuthenticationInfo> {
    const url = `${this.#baseUrl}/token/2fa/${payload.accountId}`;

    return this.#httpClient
      .patch<{
        base32: string;
        qr_image_url: string;
      }>(url, { type: payload.type })
      .pipe(
        map((data) => ({
          qrImageUrl: data.qr_image_url,
          id: data.base32
        }))
      );
  }

  check2faVerificationCode(
    payload: Check2faVerificationCodeReqPayload
  ): Observable<boolean> {
    const url = `${this.#baseUrl}/token/verification-code/2fa-type`;

    const body = {
      account_id: payload.accountId,
      verification_code: payload.verificationCode,
      tfa_type: payload.tfaType,
      secret: payload.secret
    };

    return this.#httpClient.post<boolean>(url, body);
  }

  checkDeviceVerificationCode(data: {
    jwt: string;
    accountId: number;
    verificationCode: string;
    tfaType: VerificationOptionEnum;
  }): Observable<{ isValid: boolean }> {
    const url = `${this.#baseUrl}/token/verification-code/check`;
    let localIdent = localStorage.getItem(IDENT_KEY);
    const identExpDate = localStorage.getItem(IDENT_EXP_DATE_KEY)
      ? new Date(+localStorage.getItem(IDENT_EXP_DATE_KEY))
      : null;

    if (identExpDate && identExpDate.getTime() < Date.now()) {
      localIdent = undefined;
    }

    const body = {
      jwt: data.jwt,
      account_id: data.accountId,
      verification_code: data.verificationCode,
      ident: localIdent ? localIdent : undefined,
      tfa_type: data.tfaType
    };

    return this.#httpClient
      .post<{ is_valid: boolean; ident?: string }>(url, body)
      .pipe(
        map(({ is_valid, ident }) => {
          if (!localIdent) {
            const expDate = new Date();
            // Add 10 years to the current date
            expDate.setFullYear(expDate.getFullYear() + 10);
            localStorage.setItem(IDENT_KEY, ident);
            localStorage.setItem(
              IDENT_EXP_DATE_KEY,
              expDate.getTime().toString()
            );
          }

          return { isValid: is_valid };
        })
      );
  }

  updateDeviceStatusToVerified(accountId: number): Observable<{}> {
    const url = `${this.#baseUrl}/token/verify-device/${accountId}`;

    return this.#httpClient.patch<{}>(url, {});
  }

  updateUserAgreement(
    obj: IAgreementReqBody,
    accountId: number
  ): Observable<{ token: string }> {
    const url = `${this.#baseUrl}/${accountId}`;

    return this.#httpClient.put<{ token: string }>(url, {
      security_question: obj.securityQuestion,
      security_answer: obj.securityAnswer,
      currency: obj.currency,
      aa_version: obj.aaVersion,
      first_name: obj.firstName,
      middle_name: obj.middleName,
      last_name: obj.lastName,
      contact_name: obj.contactName,
      name_suffix: obj.nameSuffix,
      phone_number: obj.phoneNumber,
      secret_pin: obj.secretPin
    });
  }

  logout(accountId: number, logoutFormAllDevices: boolean): Observable<{}> {
    const url = `${this.#baseUrl}/token/logout`;

    const body = {
      account_id: accountId,
      logout_form_all_devices: logoutFormAllDevices
    };

    return this.#httpClient.post<{}>(url, body);
  }

  createAccount(body: ICreateAccountReqBody): Observable<unknown> {
    const url = `${this.#baseUrl}`;

    return this.#httpClient.post(url, body);
  }

  resetPassword(email: { email: string }): Observable<unknown> {
    const url = `${this.#baseUrl}/password/_reset`;
    return this.#httpClient.post(url, email);
  }

  verifyUser(value: IUserVerifyReqBody) {
    const url = `${this.#baseUrl}/${value.id}/_verify`;

    return this.#httpClient.post(url, value);
  }

  recoverPassword(value: IRecoverPasswordReqBody): Observable<unknown> {
    const url = `${this.#baseUrl}/password/_recover`;
    delete value.confirmPassword;

    return this.#httpClient.post(url, value);
  }

  verifyEmail(data: IUserVerifyReqBody) {
    const url = `${this.#baseUrl}/${data.id}/_verify-email`;

    return this.#httpClient.post(url, data);
  }
}
